/* Global reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #121212;
  color: #fff;
}

h1, h2, h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

/* Loader container styles */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #121212; /* Match the website's dark theme */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Loader content */
.loader-content {
  text-align: center;
}

/* Loader logo */
.loader-logo {
  width: 150px;
  height: auto;
  animation: spin 2s linear infinite;
}

/* Spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader animation (optional: bouncing dots or similar effect) */
.loader-animation {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.loader-animation::after,
.loader-animation::before,
.loader-animation span {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #ff6a00;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
}

.loader-animation::after {
  animation-delay: 0.2s;
}

.loader-animation::before {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Centered Navbar */
.centered-navbar {
  position: absolute;  /* Positioned on top of the Hero section */
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 0;
  z-index: 10;  /* Makes sure it's above the hero section */
  background: rgba(0, 0, 0, 0.5);  /* Transparent dark background */
}

.nav-icon {
  font-size: 2rem;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
}

.nav-icon:hover {
  transform: scale(1.2);
  color: #1da1f2; /* Twitter/X Blue */
}

/* Hero Section with Integrated Navbar */
.hero {
  position: relative;
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #6A0DAD, #FFD700);
  color: white;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: fadeIn 1s ease-out;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  animation: fadeIn 1.5s ease-out;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Animation for Hero Text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hero Section */
.hero {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  background: linear-gradient(180deg, #6A0DAD, #FFD700);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Hero Content */
.hero-content {
  max-width: 600px;
  z-index: 2; /* Ensure the content stays above the background */
  animation: fadeInSlideDown 1.5s ease-in-out forwards;
}

.hero-content h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  animation: fadeIn 2s ease-in-out;
}

/* CTA Buttons */
.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.btn-primary {
  background-color: #32CD32;
  color: white;
}

.btn-primary:hover {
  background-color: #28a745;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #1E90FF;
  color: white;
}

.btn-secondary:hover {
  background-color: #007bff;
  transform: scale(1.05);
}

/* Hero Image */
.hero-image-container {
  position: relative;
  z-index: 1;
  max-width: 500px;
  margin-top: 2rem;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out;
}

/* Hover Animation */
.hero-image-container:hover {
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.hero-image-container:hover .hero-image {
  transform: scale(1.1);
}


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInSlideDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 5rem 1rem;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 0.5rem;
  }

  .btn {
    width: 100%;
  }

  .hero-image {
    max-width: 80%;
    margin-top: 1rem;
  }
}

/* Why Memecoin Beginners */
.why-us {
  text-align: center;
  padding: 60px 20px;
  background-color: #1a1a1a;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  animation: fadeInUp 1.5s ease-in-out;
}

.why-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.why-us p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1.8;
  color: #aaa;
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Course Overview Section */
.course-overview {
  text-align: center;
  padding: 80px 20px;
  background: #222;
   color: #fff;
}

.course-overview h2 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.course-overview ul {
  list-style: none;
  padding: 0;
}

.course-overview ul li {
  font-size: 1.5rem;
  margin: 15px 0;
  transition: transform 0.3s ease;
}

.course-overview ul li:hover {
  transform: translateX(10px);
}

.course-overview ul li::before {
  content: '👉';
  margin-right: 10px;
  color: #ff6a00;
}

@media screen and (max-width: 768px) {
  .course-overview ul li {
    font-size: 1.2rem;
  }
}

/* Testimonials Section */
.testimonials {
  padding: 80px 20px;
  text-align: center;
  background: #121212;
  color: #eee;
}

.testimonials h2 {
  font-size: 3rem;
  margin-bottom: 40px;
}

.testimonial {
  font-size: 1.5rem;
  margin: 30px 0;
  font-style: italic;
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards;
}

.testimonial span {
  display: block;
  margin-top: 10px;
  color: #ff6a00;
}

.testimonial:nth-child(1) {
  animation-delay: 0.5s;
}

.testimonial:nth-child(2) {
  animation-delay: 1s;
}

.testimonial:nth-child(3) {
  animation-delay: 1.5s;
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f9fc;
  color: #333;
}

/* General Styling */
.pricing-plan {
  background-color: #f9f9f9;
  padding: 2rem;
  text-align: center;
  font-family: Arial, sans-serif;
}

.pricing-heading {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.pricing-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-info {
  text-align: left;
  margin-bottom: 2rem;
}

/* Price Display */
.pricing-title {
  font-size: 2.5rem;
  color: #2e8b57;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.price-strike {
  font-size: 1.5rem;
  color: #999;
  text-decoration: line-through;
}

.current-price {
  font-size: 2.5rem;
  color: #2e8b57;
  font-weight: bold;
}

.pricing-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

.installment-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.installment-icon {
  margin-right: 10px;
  width: 40px;
  height: auto;
}

.toggle-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
}

.coupon-info {
  background: #e8f5e9;
  padding: 1rem;
  border-radius: 5px;
  color: #2e7d32;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.buy-now-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s, transform 0.2s;
}

.buy-now-btn:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}



/* Footer Section */
footer {
  background: #121212;
  color: #fff;
  padding: 30px;
  text-align: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.footer-links li {
  display: inline;
  margin: 0 20px;
  font-size: 1.2rem;
}

.footer-links li a {
  text-decoration: none;
  color: #bbb;
  transition: color 0.3s ease;
}

.footer-links li a:hover {
  color: #ff6a00;
}

.social-media a {
  color: #fff;
  margin: 0 10px;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #ff6347;
}

/* Meme Coin Timeline Section */
.meme-coin-timeline {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #ffefd5, #ffe4b5);
  text-align: center;
  color: #333;
}

.meme-coin-timeline h2 {
  font-size: 2.5rem;
  color: #6a0dad;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.meme-coin-timeline h2::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: #32cd32;
  margin: 0.5rem auto 0;
}

.timeline-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.timeline-item {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.timeline-item:hover {
  transform: translateY(-10px) rotate(5deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.timeline-year {
  font-size: 1.8rem;
  font-weight: bold;
  color: #32cd32;
  margin-bottom: 1rem;
}

/* Spinning Effect for Coin Images */
.coin-image {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  transition: transform 0.6s ease-in-out;
}

.coin-image:hover {
  transform: rotate(360deg);
}

.timeline-content h3 {
  font-size: 1.4rem;
  color: #6a0dad;
  margin-bottom: 0.5rem;
}

.timeline-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .timeline-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .timeline-item {
    width: 90%;
  }

  .meme-coin-timeline h2 {
    font-size: 2rem;
  }
}

/* General styling */
.course-outline {
  max-width: 900px;
  margin: 3rem auto;
  padding: 1rem;
  text-align: center;
  font-family: Arial, sans-serif;
  animation: fadeIn 1.5s ease-in-out;
}

.course-heading {
  font-size: 2.5rem;
  color: #6A0DAD;
  margin-bottom: 1rem;
  animation: slideDown 1s ease;
}

.course-abstract {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
  line-height: 1.5;
}

/* Course Sections */
.course-section {
  margin-bottom: 2rem;
  text-align: left;
}

.section-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
}

.lesson-list {
  list-style: none;
  padding: 0;
}

.lesson-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.lesson-title {
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.start-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #32CD32;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.start-btn:hover {
  background-color: #28a745;
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
